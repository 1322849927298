import React from "react"
import { Link } from "gatsby"

export default () => (
  <div className="not-found">
    <h1 className='headline'>Page not found.</h1>
    <p>お探しのページは削除されたか、移動しました。</p>
    <Link to="/" className="external">ホームに戻る</Link>
  </div>
)
